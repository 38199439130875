@import '../../common/variables.css';

.container {
  width: 100%;
  box-sizing: border-box;
}

.xs {
  max-width: var(--container-xs);
}

.sm {
  max-width: var(--container-sm);
}

.md {
  max-width: var(--container-md);
}

.lg {
  max-width: var(--container-lg);
}

.xl {
  max-width: var(--container-xl);
}
